exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-artists-index-js": () => import("./../../../src/pages/artists/index.js" /* webpackChunkName: "component---src-pages-artists-index-js" */),
  "component---src-pages-blank-js": () => import("./../../../src/pages/blank.js" /* webpackChunkName: "component---src-pages-blank-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-history-index-js": () => import("./../../../src/pages/history/index.js" /* webpackChunkName: "component---src-pages-history-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mailing-js": () => import("./../../../src/pages/mailing.js" /* webpackChunkName: "component---src-pages-mailing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-showroom-js": () => import("./../../../src/pages/showroom.js" /* webpackChunkName: "component---src-pages-showroom-js" */),
  "component---src-pages-studio-index-js": () => import("./../../../src/pages/studio/index.js" /* webpackChunkName: "component---src-pages-studio-index-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-works-index-js": () => import("./../../../src/pages/works/index.js" /* webpackChunkName: "component---src-pages-works-index-js" */),
  "component---src-templates-artfair-template-js": () => import("./../../../src/templates/artfair-template.js" /* webpackChunkName: "component---src-templates-artfair-template-js" */),
  "component---src-templates-artfairs-template-js": () => import("./../../../src/templates/artfairs-template.js" /* webpackChunkName: "component---src-templates-artfairs-template-js" */),
  "component---src-templates-artist-bio-template-js": () => import("./../../../src/templates/artist-bio-template.js" /* webpackChunkName: "component---src-templates-artist-bio-template-js" */),
  "component---src-templates-artist-edition-js": () => import("./../../../src/templates/artist-edition.js" /* webpackChunkName: "component---src-templates-artist-edition-js" */),
  "component---src-templates-artist-editions-js": () => import("./../../../src/templates/artist-editions.js" /* webpackChunkName: "component---src-templates-artist-editions-js" */),
  "component---src-templates-exhibition-template-js": () => import("./../../../src/templates/exhibition-template.js" /* webpackChunkName: "component---src-templates-exhibition-template-js" */),
  "component---src-templates-exhibitions-template-js": () => import("./../../../src/templates/exhibitions-template.js" /* webpackChunkName: "component---src-templates-exhibitions-template-js" */),
  "component---src-templates-inthestudio-template-js": () => import("./../../../src/templates/inthestudio-template.js" /* webpackChunkName: "component---src-templates-inthestudio-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */)
}

