module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-static-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/Users/brian/Projects/ULAE2019static/static/admin/config.js","enableIdentityWidget":true,"publicPath":"admin","htmlTitle":"Content Manager","includeRobots":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"zbx5jzg"}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1180,"showCaptions":true,"linkImagesToOriginal":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-flexsearch/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en"],"type":"MarkdownRemark","fields":[{"name":"title","indexed":true,"resolver":"frontmatter.title","attributes":{"encode":"balance","tokenize":"forward","threshold":false,"depth":false},"store":true},{"name":"artist","indexed":true,"resolver":"frontmatter.displayName","attributes":{"encode":"balance","tokenize":"forward","threshold":false,"depth":false},"store":true},{"name":"artistName","indexed":true,"resolver":"frontmatter.artist","attributes":{"encode":"balance","tokenize":"forward","threshold":false,"depth":false},"store":true},{"name":"displayDate","indexed":true,"attributes":{"encode":"balance","tokenize":"forward","threshold":false,"depth":false},"resolver":"frontmatter.displayDate","store":true},{"name":"fields","indexed":false,"resolver":"fields","store":true},{"name":"frontmatter","indexed":false,"resolver":"frontmatter","store":true},{"name":"templateKey","indexed":false,"resolver":"frontmatter.templateKey","store":true},{"name":"artistId","indexed":false,"resolver":"frontmatter.artistId","store":true},{"name":"artistSlug","indexed":false,"resolver":"fields.artist.fields.slug","store":true},{"name":"sortEventDate","indexed":false,"resolver":"frontmatter.dateEnd","store":true},{"name":"sortEditionDate","indexed":false,"resolver":"frontmatter.releaseDate","store":true},{"name":"url","indexed":false,"resolver":"fields.slug","store":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-15428749-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
